import { computed, ref, watch } from '@vue/composition-api';
import useTaxiTransaction from '@/modules/taxi/composables/useTaxiTransaction';

const useTaxiTransactionsList = () => {
  const { fetchTransactions } = useTaxiTransaction();

  const perPageOptions = [10, 25, 50, 100];
  const tableColumns = [
    { key: 'id', label: '#', sortable: true },
    { key: 'transactionStatusId', label: 'Status' },
    { key: 'taxiId', label: 'Taxi Driver', sortable: true },
    { key: 'accountId', label: 'Account', sortable: true },
    { key: 'bookingId', label: 'Booking', sortable: true },
    { key: 'postId', label: 'Post', sortable: true },
    { key: 'tourId', label: 'Tour', sortable: true },
    { key: 'amount', sortable: true },
    { key: 'transactionDateUtc', label: 'Date', sortable: true },
    { key: 'actions' },
  ];

  const pageNumber = ref(1);
  const orderDesc = ref(true);
  const pageSize = ref(10);
  const refTransactionList = ref(null);
  const orderBy = ref('id');
  const taxiId = ref(null);
  const transactionStatusId = ref(null);
  const totalTansactions = ref(0);
  const searchQuery = ref(null);
  const searchByDate = ref('');

  const dataMeta = computed(() => {
    const localItemsCount = refTransactionList.value
      ? refTransactionList.value.localItems.length
      : 0;
    return {
      from: pageSize.value * (pageNumber.value - 1) + (localItemsCount ? 1 : 0),
      to: pageSize.value * (pageNumber.value - 1) + localItemsCount,
      of: totalTansactions.value,
    };
  });

  watch(
    [pageNumber, pageSize, taxiId, transactionStatusId, searchByDate],
    () => {
      refTransactionList.value.refresh();
    }
  );

  const clearSearchByDate = () => {
    searchByDate.value = '';
  };

  const getTransactionList = (_, callback) => {
    const params = {
      orderBy: orderBy.value,
      orderDesc: orderDesc.value,
      searchByDate: searchByDate.value === '' ? null : searchByDate.value,
      pageSize: pageSize.value,
      pageNumber: pageNumber.value,
      taxiId: taxiId.value,
      transactionStatusId: transactionStatusId.value,
      searchQuery: searchQuery.value,
    };

    fetchTransactions(params).then(({ transactions, total }) => {
      callback(transactions);
      totalTansactions.value = total;
    });
  };

  return {
    // Const
    perPageOptions,
    tableColumns,

    // Reactive
    orderBy,
    orderDesc,
    pageNumber,
    pageSize,
    refTransactionList,
    searchQuery,
    taxiId,
    transactionStatusId,
    totalTansactions,
    searchByDate,

    // Computed
    dataMeta,

    // Method
    getTransactionList,
    clearSearchByDate,
  };
};

export default useTaxiTransactionsList;
