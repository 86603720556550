<template>
  <div>
    <taxi-transaction-details
      v-if="isSidebarActive"
      :is-sidebar-active.sync="isSidebarActive"
      :transaction="transaction"
      @form-submitted="formSubmitted"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            sm="4"
            class="d-flex align-items-center justify-content-start mb-75"
          >
            <label>Show</label>
            <v-select
              v-model="pageSize"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <b-col
            cols="12"
            sm="8"
            class="d-flex align-items-center justify-content-start justify-content-sm-end"
          >
            <b-form-group>
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Press ENTER to search"
                @keyup.enter="refTransactionList.refresh()"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <div
            class="d-flex flex-md-row flex-column align-items-start justify-content-end w-100 px-1 px-md-0"
          >
            <div class="d-flex d-flex-row">
              <flat-pickr
                v-model="searchByDate"
                class="form-control"
                :config="{ dateFormat: 'd/m/Y', enableTime: false }"
                readonly="false"
                placeholder="Date"
              />
              <b-button
                v-if="searchByDate !== ''"
                variant="flat-secondary"
                class="btn-icon ml-50"
                @click="clearSearchByDate"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </div>

            <v-select
              v-model="transactionStatusId"
              :reduce="(status) => status.id"
              :options="transactionStatusList"
              class="invoice-filter-select my-50 my-md-0 mx-md-1"
              placeholder="Select Status"
              label="name"
            >
              <template #selected-option="{ name }">
                <span class="text-truncate overflow-hidden">
                  {{ name }}
                </span>
              </template>

              <template #option="{ name }">
                <span class="text-truncate overflow-hidden">
                  {{ name }}
                </span>
              </template>
            </v-select>

            <v-select
              v-model="taxiId"
              :reduce="(taxi) => taxi.id"
              :options="taxiList"
              class="invoice-filter-select"
              placeholder="Select Driver"
              label="licenceNum"
            >
              <template #option="{ firstName, lastName, licenceNum }">
                <span>
                  <span class="font-weight-bolder">{{ licenceNum }}</span>
                  -
                  <small>{{ `${firstName} ${lastName}` }}</small>
                </span>
              </template>

              <template #selected-option="{ firstName, lastName, licenceNum }">
                <span class="font-weight-bolder">{{ licenceNum }}</span>
              </template>
            </v-select>
          </div>
        </b-row>
      </div>

      <b-table
        ref="refTransactionList"
        class="position-relative"
        :items="getTransactionList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="orderDesc"
      >
        <!-- Column: Id -->
        <template #cell(id)="data">
          <p class="font-weight-bold d-block text-nowrap text-primary">
            {{ data.item.id }}
          </p>
        </template>

        <template #cell(transactionStatusId)="data">
          <b-badge
            :variant="`light-${resolveTransactionStatusVariantAndIcon(
              data.item.transactionStatus
            )}`"
          >
            {{ data.item.transactionStatus.name }}
          </b-badge>
        </template>

        <template #cell(taxiId)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                variant="light-secondary"
                :text="
                  getInitials(data.item.taxi.firstName, data.item.taxi.lastName)
                "
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ `${data.item.taxi.firstName} ${data.item.taxi.lastName}` }}
            </span>
            <small class="text-muted">{{ data.item.taxi.licenceNum }}</small>
          </b-media>
        </template>

        <template #cell(accountId)="data">
          <b-media
            vertical-align="center"
            v-if="data.item.accountId"
          >
            <template #aside>
              <b-avatar
                size="32"
                variant="light-secondary"
              >
                <feather-icon
                  size="16"
                  icon="GridIcon"
                />
              </b-avatar>
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.account.name }}
            </span>
          </b-media>
        </template>

        <template #cell(bookingId)="data">
          <b-link
            v-if="data.item.bookingId"
            :to="{
              name: 'booking-detail',
              params: { id: data.item.bookingId },
            }"
            class="font-weight-bold"
          >
            {{ data.item.bookingId ? data.item.booking.voucherNum : '' }}
          </b-link>
        </template>

        <template #cell(postId)="data">
          <b-link
            v-if="data.item.postId"
            :to="{
              name: 'post-detail',
              params: { id: data.item.postId },
            }"
            class="font-weight-bold"
          >
            {{ data.item.postId }}
          </b-link>
        </template>

        <template #cell(tourId)="data">
          <b-link
            v-if="data.item.tourId"
            :to="{
              name: 'frontier-tour-detail',
              params: { id: data.item.tourId },
            }"
            class="font-weight-bold"
          >
            {{ data.item.tourId ? data.item.tour.voucherNum : '' }}
          </b-link>
        </template>

        <template #cell(amount)="data">
          <span
            :class="data.item.amount < 0 ? 'text-danger' : 'text-success'"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ formatCurrency(data.item.amount) }}
          </span>
          <small class="text-muted">{{
            formatCurrency(data.item.newBalance)
          }}</small>
        </template>

        <template #cell(transactionDateUtc)="data">
          <p class="d-block text-nowrap">
            {{ dateFormated(data.item.transactionDateUtc) }}
          </p>
        </template>

        <template #cell(actions)="data">
          <feather-icon
            :id="`transaction-row-${data.item.id}-detail`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="showTransaction(data.item)"
          />
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-center justify-content-md-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-center justify-content-md-end"
          >
            <b-pagination
              v-model="pageNumber"
              :total-rows="totalTansactions"
              :per-page="pageSize"
              first-number
              last-number
              class="mb-0 mt-1 mt-md-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BBadge,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  BTooltip,
} from 'bootstrap-vue';
import { ref } from '@vue/composition-api';
import flatPickr from 'vue-flatpickr-component';
import TaxiTransactionDetails from '@/modules/taxi/components/TaxiTransactionDetails.vue';
import useTaxi from '@/modules/taxi/composables/useTaxi';
import useTaxiTransaction from '@/modules/taxi/composables/useTaxiTransaction';
import useTaxiTransactionsList from '@/modules/taxi/composables/useTaxiTransactionsList';
import useUtils from '@/composables/useUtils';
import vSelect from 'vue-select';

export default {
  setup() {
    const {
      formatCurrency,
      formatDateDdMmYyyyHi,
      convertUtcToLocalDate,
      generateInitials,
    } = useUtils();
    const { getTaxiList } = useTaxi();
    const {
      fetchTransactionStatus,
      resolveTransactionStatusVariantAndIcon,
      resolveTransactionTypeVariantAndIcon,
    } = useTaxiTransaction();
    const {
      dataMeta,
      getTransactionList,
      orderBy,
      orderDesc,
      pageNumber,
      pageSize,
      perPageOptions,
      refTransactionList,
      searchQuery,
      transactionStatusId,
      tableColumns,
      taxiId,
      totalTansactions,
      searchByDate,
      clearSearchByDate,
    } = useTaxiTransactionsList();

    const isSidebarActive = ref(false);
    const transaction = ref(null);
    const taxiList = ref([]);
    const transactionStatusList = ref([]);

    const dateFormated = (date) =>
      formatDateDdMmYyyyHi(convertUtcToLocalDate(date));

    const showTransaction = (transactionSelected) => {
      transaction.value = { ...transactionSelected };
      isSidebarActive.value = true;
    };

    const formSubmitted = () => {
      refTransactionList.value.refresh();
      isSidebarActive.value = false;
    };

    const getInitials = (firstName, lastName) =>
      (firstName, lastName) ? generateInitials(firstName, lastName) : null;

    const initData = async () => {
      const { taxis } = await getTaxiList();
      const transactionStatus = await fetchTransactionStatus();
      taxiList.value = taxis;
      transactionStatusList.value = transactionStatus;
    };

    initData();

    return {
      dataMeta,
      dateFormated,
      formatCurrency,
      formSubmitted,
      getInitials,
      getTransactionList,
      isSidebarActive,
      orderBy,
      orderDesc,
      pageNumber,
      pageSize,
      perPageOptions,
      refTransactionList,
      resolveTransactionStatusVariantAndIcon,
      resolveTransactionTypeVariantAndIcon,
      searchQuery,
      showTransaction,
      transactionStatusId,
      tableColumns,
      taxiId,
      taxiList,
      totalTansactions,
      transaction,
      transactionStatusList,
      clearSearchByDate,
      searchByDate,
    };
  },
  components: {
    BAvatar,
    BBadge,
    BButton,
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormInput,
    BLink,
    BMedia,
    BPagination,
    BRow,
    BTable,
    BTooltip,
    TaxiTransactionDetails,
    vSelect,
    flatPickr,
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
